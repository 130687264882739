body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.coming-soon {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: black;
  flex-direction: column;
  row-gap: 3rem;
}

.coming-soon .logo img {
  width: 25vw;
  min-width: 280px;
  max-width: 70vw;
}

.coming-soon-message h1 {
  color: white;
  text-align: center;
  line-height: 1.5;
  font-weight: 500;
  letter-spacing: 2px;
}

.coming-soon-message span {
  color: darkgoldenrod;
  text-transform: uppercase;
  font-weight: 700;
}
